<template>
  <b-card class="m-2" header="Shared Response">
    <div v-if="loading" class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>

    <div v-if="!loading && error == false">
      <b-alert show variant="secondary"
        >This response was shared by an Eunoe user. To learn more, visit the
        <b-link href="https://eunoehealth.com/">Eunoe Homepage</b-link></b-alert
      >

      <b-card
        class="d-flex justify-content-between m-auto"
        style="max-width: 640px"
      >
        <b-row
          ><b-col
            cols="8"
            class="d-flex align-items-center justify-content-start"
            >{{ question }}</b-col
          ><b-col
            cols="4"
            class="d-flex align-items-center justify-content-end"
          >
            <span v-if="!playing">
              <b-button
                size="sm"
                @click="toggleAudio()"
                variant="primary"
                class="btn-circle mr-1"
              >
                <b-icon icon="play" aria-hidden="true"></b-icon>
              </b-button>
            </span>
            <span v-if="playing">
              <b-button
                size="sm"
                @click="toggleAudio()"
                variant="secondary"
                class="btn-circle mr-1"
              >
                <b-icon icon="pause" aria-hidden="true"></b-icon>
              </b-button> </span></b-col
        ></b-row>

        <div
          class="mt-4"
          @click="setProgress($event, $refs.audioProgress)"
          ref="audioProgress"
        >
          <b-progress
            :value="progress"
            :max="1"
            class="notransition"
            height=".75rem"
          ></b-progress>
        </div>
      </b-card>
    </div>

    <b-alert variant="danger" :show="error != ''">{{ error }}</b-alert>
  </b-card>
</template>

<script>
import API from "@/api";

export default {
  name: "Shared",
  data: () => ({
    loading: true,
    error: false,
    playing: false,
    question: "",
    audioCurrentTime: 0,
    audioDuration: 0,
    audio: null,
  }),
  computed: {
    progress() {
      return this.audioCurrentTime / this.audioDuration;
    },
  },
  async created() {
    if (this.$route.params.token) {
      let result = await API.processShareToken(this.$route.params.token);
      console.log(result);

      if (result.error) {
        this.error = result.error;
      } else {
        this.question = result.text;

        this.audio = new Audio();

        this.audio.addEventListener("play", () => {
          this.playing = true;
        });

        this.audio.addEventListener("loadedmetadata", () => {
          this.audioDuration = this.audio.duration;
        });

        this.audio.addEventListener("ended", () => {
          this.playing = false;
        });

        this.audio.addEventListener("timeupdate", () => {
          if (this.playing) {
            this.audioCurrentTime = this.audio.currentTime;
          }
        });

        this.audio.src = result.audio_url;
      }
      this.loading = false;
    } else {
      // No share token present.
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    toggleAudio() {
      if (this.audio.paused) {
        this.playAudio();
      } else {
        this.pauseAudio();
      }
    },
    async playAudio() {
      this.playing = true;
      this.audio.play();
    },
    pauseAudio() {
      this.playing = false;
      this.audio.pause();
    },
    async setProgress(event, element) {
      let rect = element.getBoundingClientRect();
      let x = (event.clientX - rect.left) / rect.width;

      this.audioCurrentTime = x * this.audioDuration;
      this.audio.currentTime = this.audioCurrentTime;
    },
  },
};
</script>